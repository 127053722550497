export const ROUTES = {
  onboarding: {
    index: '/onboarding',
    login: '/onboarding/login',
    bellLogin: '/onboarding/bellLogin',
    magicLink: '/onboarding/magic-link',
    globalAuth: '/onboarding/global-auth',
    resetPassword: '/onboarding/reset-password',
  },
  partnerAuthenticationCallback: '/partner/callback',
  home: {
    index: '/',
    wlan: '/wlan',
  },
  shield: '/shield',
  network: '/network',
  zones: {
    secure: {
      index: '/zones/secure',
      approved: '/zones/secure/approved-devices',
      approvedByGroup: '/zones/secure/approved-devices/:groupId',
      unapproved: '/zones/secure/unapproved-devices',
      blocked: '/zones/secure/blocked-devices',
      quarantined: '/zones/secure/quarantined-devices',
    },
    employee: {
      index: '/zones/employee',
      approved: '/zones/employee/approved-devices',
      unapproved: '/zones/employee/unapproved-devices',
      blocked: '/zones/employee/blocked-devices',
      quarantined: '/zones/employee/quarantined-devices',
    },
    guest: {
      index: '/zones/guest',
      popularDevices: '/zones/guest/popular-devices',
    },
  },
  settings: {
    index: '/settings',
    secure: '/settings/secure',
    employee: '/settings/employee',
    employeeLogin: '/settings/employeeLogin',
    guest: '/settings/guest',
    shield: '/settings/shield',
    pods: '/settings/pods',
    support: '/settings/support',
    account: '/settings/account',
    businessInfo: '/settings/business-info',
  },
  device: '/device',
  devices: '/devices',
  deviceByMac: '/device/:mac',
  employee: '/employee',
  employees: '/employees',
  employeeById: '/employee/:id',
  employeeByIdAssignedDevices: '/employee/:id/assigned-devices',
  appTimeInsightsByNetworkId: '/appTimeInsights/:network',
  appTimeInsightsByEmployeeId: '/appTimeInsights/specificEmployee/:personId',
  ssidConfiguration: {
    configuration: '/network-configuration',
    pods: '/network-configuration/pods',
    support: '/network-configuration/support',
    account: '/network-configuration/account',
    secure: '/network-configuration/secure',
    employee: '/network-configuration/employee',
    guest: '/network-configuration/guest',
  },
  insurance: {
    index: '/insurance',
    next: '/insurance/next',
  },
};
const pathToRegexp = require('path-to-regexp');
const deviceByMacPathRegex = pathToRegexp(ROUTES.deviceByMac);
const employeeByIdPathRegex = pathToRegexp(ROUTES.employeeById);

export const isHomeRoute = (route: string) => route === ROUTES.home.index;
export const isDeviceRoute = (route: string) => {
  const isMatch = deviceByMacPathRegex.test(route);
  return isMatch;
};

export const isEmployeeRoute = (route: string) => {
  const isMatch = employeeByIdPathRegex.test(route);
  return isMatch;
};

export const isZonesRoute = (route: string) =>
  route === ROUTES.zones.secure.index ||
  route === ROUTES.zones.employee.index ||
  route === ROUTES.zones.guest.index;

export const isSettingsRoute = (route: string) =>
  route === ROUTES.settings.index ||
  route === ROUTES.settings.secure ||
  route === ROUTES.settings.employee ||
  route === ROUTES.settings.employeeLogin ||
  route === ROUTES.settings.guest ||
  route === ROUTES.settings.shield ||
  route === ROUTES.settings.pods ||
  route === ROUTES.settings.support ||
  route === ROUTES.settings.account ||
  route === ROUTES.ssidConfiguration.pods ||
  route === ROUTES.ssidConfiguration.support ||
  route === ROUTES.ssidConfiguration.account ||
  route === ROUTES.settings.businessInfo;

export const isNetworkRoute = (route: string) =>
  route === ROUTES.network || route === ROUTES.shield;
