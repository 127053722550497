import cn from 'classnames';
import React, {
  FunctionComponent,
  HTMLProps,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import type { LocationId, LocationSelection, Nullable } from 'Consts/types';
import { isSettingsRoute, isZonesRoute, ROUTES } from 'Consts/routes';

import ListItem from 'UI/Components/Lists/List standard';

import { IconNames } from 'UI/Elements/Icon';

import LocationsSelector from '../Locations';

import { NavPanelItemProps, Props } from '../../index';

import sharedStyles from '../../shared.module.css';
import styles from './style.module.css';
import { matchPath } from 'react-router-dom';
import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import {
  getConfigurationFromDomain,
  isPureDevDomain,
} from 'subDomainConfiguration';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';

import { memo } from 'react';

type NavLinkProps = NavPanelItemProps & {
  label: string;
  withLeftPadding?: boolean;
} & HTMLProps<HTMLDivElement>;

type ZonesLinkProps = {
  isExpanded: boolean;
  currentRoute: string;
  zonesExpanded: boolean;
  onClick: (ev: React.MouseEvent) => void;
  onFocus?: (ev: React.FocusEvent) => void;
} & HTMLProps<HTMLDivElement>;

type SettingsLinkProps = {
  isExpanded: boolean;
  currentRoute: string;
  settingsExpanded: boolean;
  onClick: (ev: React.MouseEvent) => void;
  onFocus?: (ev: React.FocusEvent) => void;
} & HTMLProps<HTMLDivElement>;

type DesktopNavPanelUIProps = Props & {
  isOpen: boolean;
  currentLocation: Nullable<LocationSelection>;
  locations: Nullable<LocationSelection[]>;
  onSwitchToLocation: (locationId: LocationId) => void;
};

const getIconFromRoute = (route: string, selected?: boolean) => {
  const map = {
    [ROUTES.home.index]: IconNames.Home,
    [ROUTES.devices]: IconNames.Devices,
    [ROUTES.zones.secure.index]: IconNames.Layers,
    [ROUTES.zones.employee.index]: IconNames.Layers,
    [ROUTES.zones.guest.index]: IconNames.Layers,
    [ROUTES.shield]: IconNames.Shield,
    [ROUTES.network]: IconNames.Topology,
    [ROUTES.settings.index]: IconNames.Settings,
    [ROUTES.insurance.index]: IconNames.Guard,
    [ROUTES.employees]: IconNames.PersonProfile,
  };

  const selectedMap = {
    [ROUTES.home.index]: IconNames.HomeFill,
    [ROUTES.devices]: IconNames.DevicesFill,
    [ROUTES.zones.secure.index]: IconNames.Layers,
    [ROUTES.zones.employee.index]: IconNames.Layers,
    [ROUTES.zones.guest.index]: IconNames.Layers,
    [ROUTES.shield]: IconNames.ShieldFill,
    [ROUTES.network]: IconNames.Topology,
    [ROUTES.settings.secure]: IconNames.SettingsFill,
    [ROUTES.insurance.index]: IconNames.Guard,
    [ROUTES.employees]: IconNames.PersonProfile,
  };

  return selected ? selectedMap[route] : map[route];
};

export const getNavItemThemeFromEnvironment = () => {
  const environment = getConfigurationFromDomain();
  return environment.id === 'bell' ? 'bell' : '';
};

export const NavLink: FunctionComponent<NavLinkProps> = ({
  label,
  withLeftPadding,
  currentRoute,
  additionalRoutesForSelection,
  route,
  onNavigate,
  ...rest
}) => {
  const handleNavigate = useCallback(
    () => onNavigate(route),
    [route, onNavigate]
  );
  const selected =
    currentRoute === route ||
    (additionalRoutesForSelection &&
      additionalRoutesForSelection.filter((checkstring) => {
        return matchPath({ path: checkstring }, currentRoute);
      })?.length);

  const iconProps = { iconProps: { name: getIconFromRoute(route) } };

  return (
    <ListItem
      role="link"
      className={cn(sharedStyles.navLink, {
        [sharedStyles.selected]: selected,
      })}
      L1Props={!isZonesRoute(route) ? iconProps : {}}
      L2Props={{ label, withLeftPadding }}
      onClick={handleNavigate}
      navItemTheme={getNavItemThemeFromEnvironment()}
      ariaLabel={label}
      {...rest}
    />
  );
};

const ZonesLink: FunctionComponent<ZonesLinkProps> = ({
  isExpanded,
  currentRoute,
  zonesExpanded,
  onClick,
  onFocus,
  ...rest
}) => {
  const { t } = useTranslation(['translation', 'csp']);
  const selected = isZonesRoute(currentRoute);

  const iconProps = { name: getIconFromRoute(ROUTES.zones.secure.index) };

  return (
    <ListItem
      role="button"
      className={cn(sharedStyles.navLink, {
        [sharedStyles.selected]: selected && !isExpanded,
      })}
      L1Props={{ iconProps }}
      L2Props={{ label: t('common.zones') }}
      RProps={{
        icon1Props: {
          name: IconNames.ChevronRight,
          className: cn(styles.zonesChevron, {
            [styles.expanded]: zonesExpanded,
          }),
        },
      }}
      onClick={onClick}
      onFocus={onFocus}
      navItemTheme={getNavItemThemeFromEnvironment()}
      ariaLabel={t('common.zones')}
      {...rest}
    />
  );
};

const SettingsLink: FunctionComponent<SettingsLinkProps> = ({
  isExpanded,
  currentRoute,
  settingsExpanded,
  onClick,
  onFocus,
  ...rest
}) => {
  const { t } = useTranslation();
  const selected = isSettingsRoute(currentRoute);

  const iconProps = { name: getIconFromRoute(ROUTES.settings.index) };

  return (
    <ListItem
      role="button"
      className={cn(sharedStyles.navLink, {
        [sharedStyles.selected]: selected && !isExpanded,
      })}
      L1Props={{ iconProps }}
      L2Props={{ label: t('common.settings') }}
      RProps={{
        icon1Props: {
          name: IconNames.ChevronRight,
          className: cn(styles.zonesChevron, {
            [styles.expanded]: settingsExpanded,
          }),
        },
      }}
      onClick={onClick}
      onFocus={onFocus}
      navItemTheme={getNavItemThemeFromEnvironment()}
      ariaLabel={t('common.settings')}
      {...rest}
    />
  );
};

const DesktopNavPanelUI: FunctionComponent<DesktopNavPanelUIProps> = ({
  className,
  isOpen,
  currentRoute,
  currentLocation,
  locations,
  onNavigate,
  onSwitchToLocation,
}) => {
  const { t } = useTranslation(['translation', 'csp']);
  const navPanelRef = useRef<HTMLDivElement>(null);

  const savedZonesExpanded = localStorage.getItem('zonesExpanded');
  const savedSettingsExpanded = localStorage.getItem('settingsExpanded');
  const namespace = useCspTranslationNamespace();

  const [zonesExpanded, setZonesExpanded] = useState(
    savedZonesExpanded === 'true'
  );

  const [settingsExpanded, setSettingsExpanded] = useState(
    savedSettingsExpanded === 'true'
  );

  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const insuranceEnabled =
    customerSupportConfigurations?.customerFeatureEnablement
      .protectInsuranceEnabled;

  const businessInfoEnabled = isPureDevDomain();

  const handleZonesItemClick = useCallback(
    (ev: React.FocusEvent | React.MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();

      const newValue = ev.type !== 'focus' ? !zonesExpanded : true;

      localStorage.setItem('zonesExpanded', newValue.toString());
      setZonesExpanded(newValue);
    },
    [currentRoute, onNavigate, zonesExpanded]
  );

  const handleSettingsItemClick = useCallback(
    (ev: React.FocusEvent | React.MouseEvent) => {
      ev.preventDefault();
      ev.stopPropagation();

      const newValue = ev.type !== 'focus' ? !settingsExpanded : true;

      localStorage.setItem('settingsExpanded', newValue.toString());
      setSettingsExpanded(newValue);
    },
    [currentRoute, onNavigate, settingsExpanded]
  );

  return (
    <CSSTransition
      nodeRef={navPanelRef}
      in={isOpen}
      className={cn(styles.navPanelWrapper, className)}
      classNames={{
        enter: styles.enter,
        exitActive: styles.exitActive,
        exit: styles.exit,
      }}
      unmountOnExit
      timeout={500}
    >
      <div ref={navPanelRef}>
        <div className={styles.navList} role="navigation">
          <NavLink
            label={t('common.home')}
            route={ROUTES.home.index}
            additionalRoutesForSelection={[ROUTES.home.wlan]}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />

          <NavLink
            label={t('devices.devices')}
            route={ROUTES.devices}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />

          <NavLink
            label={t('employeeZone.employees')}
            route={ROUTES.employees}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />

          <ZonesLink
            isExpanded={zonesExpanded}
            currentRoute={currentRoute}
            zonesExpanded={zonesExpanded}
            onClick={handleZonesItemClick}
            onFocus={handleZonesItemClick}
            aria-expanded={zonesExpanded}
          />

          {zonesExpanded && (
            <div>
              <NavLink
                label={t('common.secureZone')}
                withLeftPadding
                route={ROUTES.zones.secure.index}
                additionalRoutesForSelection={[
                  ROUTES.zones.secure.approved,
                  ROUTES.zones.secure.approvedByGroup,
                  ROUTES.zones.secure.blocked,
                  ROUTES.zones.secure.unapproved,
                  ROUTES.zones.secure.quarantined,
                ]}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />

              <NavLink
                label={t('common.employeeZone')}
                withLeftPadding
                route={ROUTES.zones.employee.index}
                additionalRoutesForSelection={[
                  ROUTES.zones.employee.approved,
                  ROUTES.zones.employee.blocked,
                  ROUTES.zones.employee.unapproved,
                  ROUTES.zones.employee.quarantined,
                  ROUTES.employee,
                  ROUTES.employeeById,
                  ROUTES.employeeByIdAssignedDevices,
                ]}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />

              <NavLink
                label={t('common.guestZone')}
                withLeftPadding
                route={ROUTES.zones.guest.index}
                additionalRoutesForSelection={[
                  ROUTES.zones.guest.popularDevices,
                ]}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />
            </div>
          )}

          <NavLink
            label={t('common.shield')}
            route={ROUTES.shield}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />

          <NavLink
            label={t('common.network')}
            route={ROUTES.network}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />

          {insuranceEnabled && (
            <NavLink
              label={t('common.insurance')}
              route={ROUTES.insurance.index}
              currentRoute={currentRoute}
              onNavigate={onNavigate}
            />
          )}

          <SettingsLink
            isExpanded={settingsExpanded}
            currentRoute={currentRoute}
            settingsExpanded={settingsExpanded}
            onClick={handleSettingsItemClick}
            onFocus={handleSettingsItemClick}
            aria-expanded={settingsExpanded}
          />

          {settingsExpanded && (
            <div>
              <NavLink
                label={t('settings.secure', { ns: namespace })}
                withLeftPadding
                route={ROUTES.settings.secure}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />

              <NavLink
                label={t('settings.employee', { ns: namespace })}
                withLeftPadding
                route={ROUTES.settings.employee}
                additionalRoutesForSelection={[ROUTES.settings.employeeLogin]}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />

              <NavLink
                label={t('settings.guest', { ns: namespace })}
                withLeftPadding
                route={ROUTES.settings.guest}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />
              <NavLink
                label={t('settings.shieldSettings')}
                withLeftPadding
                route={ROUTES.settings.shield}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />

              {businessInfoEnabled && (
                <NavLink
                  label={t('settings.businessInfo.title')}
                  withLeftPadding
                  route={ROUTES.settings.businessInfo}
                  currentRoute={currentRoute}
                  onNavigate={onNavigate}
                />
              )}

              <NavLink
                label={t('settings.pods', { ns: namespace })}
                withLeftPadding
                route={ROUTES.settings.pods}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />
              <NavLink
                label={t('settings.support')}
                withLeftPadding
                route={ROUTES.settings.support}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />
              <NavLink
                label={t('settings.account')}
                withLeftPadding
                route={ROUTES.settings.account}
                currentRoute={currentRoute}
                onNavigate={onNavigate}
              />
            </div>
          )}
        </div>

        <LocationsSelector
          currentLocation={currentLocation}
          locations={locations}
          onSwitchToLocation={onSwitchToLocation}
        />
      </div>
    </CSSTransition>
  );
};
export default memo(DesktopNavPanelUI);
