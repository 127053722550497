import cn from 'classnames';
import React, { FunctionComponent, ReactNode } from 'react';

import InputListItem, {
  InputListItemProps,
} from 'UI/Components/Lists/List input';

import Button, {
  BUTTON_THEMES,
  Props as ButtonProps,
} from 'UI/Elements/Button';
import Icon, { Props as IconProps } from 'UI/Elements/Icon';
import { Body1, Body2Medium, Heading2Medium, Heading4Medium } from 'UI/Elements/Typography';
import Card from 'UI/Elements/Card';

import colorStyles from 'Styles/colors.module.css';

import styles from './style.module.css';

import { memo } from 'react';

export type TopProps = {
  label: string;
  paragraph?: ReactNode | string;
  iconProps?: IconProps;
  className?: string;
};

const Top: FunctionComponent<TopProps> = ({
  label,
  paragraph,
  iconProps,
  className,
}) => {
  return (
    <div
      className={cn(styles.top, className, { [styles.withIcon]: !!iconProps })}
    >
      <Heading4Medium>{label}</Heading4Medium>

      {paragraph && <Body1 className={colorStyles.still400}>{paragraph}</Body1>}

      {iconProps && !paragraph && (
        <Icon {...iconProps} className={cn(styles.icon, iconProps.className)} />
      )}
    </div>
  );
};

export type MiddleProps = {
  listInputProps?: InputListItemProps;
  paragraph?: string;
  icon?: IconProps;
  iconParagraph?: ReactNode;
  label?: string;
  number?: string;
  inputShouldAutoFocus?: boolean;
};

const Middle: FunctionComponent<MiddleProps> = ({
  listInputProps,
  paragraph,
  icon,
  iconParagraph,
  number,
  inputShouldAutoFocus,
}) => {
  if (listInputProps) {
    return (
      <div className={styles.middle}>
        <InputListItem
          {...listInputProps}
          shouldAutoFocus={inputShouldAutoFocus}
        />
      </div>
    );
  }

  if (paragraph) {
    return (
      <div className={styles.middle}>
        <Body1 className={colorStyles.still400}>{paragraph}</Body1>
      </div>
    );
  }

  if (number) {
    return (
      <div className={cn(styles.middle, styles.number)}>
        <Heading2Medium className={colorStyles.black}>{number}</Heading2Medium>
      </div>
    );
  }

  if (icon || iconParagraph) {
    return (
      <div className={styles.middleIconParagraph}>
        {icon && (
          <Icon name={icon.name} />
        )}
        {iconParagraph && (
          <Body2Medium className={colorStyles.still500}>{iconParagraph}</Body2Medium>
        )}
      </div>
    );
  }

  return null;
};

export type BottomProps = {
  button1Props: ButtonProps;
  button2Props?: ButtonProps;
  button3Props?: ButtonProps;
  buttonShouldAutoFocus?: boolean;
};

const Bottom: FunctionComponent<BottomProps> = ({
  button1Props,
  button2Props,
  button3Props,
  buttonShouldAutoFocus,
}) => {
  return (
    <div className={cn(styles.bottom)}>
      <Button
        label={button1Props.label}
        theme={button1Props.theme || BUTTON_THEMES.black}
        onClick={button1Props.onClick}
        disabled={button1Props.disabled}
        fullWidth
        shouldAutoFocus={buttonShouldAutoFocus}
      />

      {button2Props && (
        <Button
          label={button2Props.label}
          theme={button2Props.theme || BUTTON_THEMES.black}
          onClick={button2Props.onClick}
          disabled={button2Props.disabled}
          fullWidth
        />
      )}

      {button3Props && (
        <Button
          label={button3Props.label}
          theme={button3Props.theme || BUTTON_THEMES.black}
          onClick={button3Props.onClick}
          disabled={button3Props.disabled}
          fullWidth
        />
      )}
    </div>
  );
};

type AlertProps = {
  topProps: TopProps;
  middleProps?: MiddleProps;
  bottomProps: BottomProps;
  className?: string;
};

const Alert: FunctionComponent<AlertProps> = ({
  topProps,
  middleProps = {},
  bottomProps,
  className,
}) => {
  return (
    <Card
      className={cn(styles.alert, className)}
      ariaLabel={topProps.label}
      ariaDescription={
        (typeof topProps?.paragraph === 'string' && topProps?.paragraph) ||
        middleProps?.paragraph ||
        ''
      }
      isAlert
    >
      <Top {...topProps} />
      <Middle
        {...middleProps}
        inputShouldAutoFocus={!!middleProps.listInputProps}
      />
      <Bottom
        {...bottomProps}
        buttonShouldAutoFocus={
          middleProps.listInputProps === undefined && !!bottomProps.button1Props
        }
      />
    </Card>
  );
};
export default memo(Alert);
